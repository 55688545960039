<template>
  <div class="shop">
    <!-- 弹框 -->
    <!-- <div class="image-container" @touchmove.prevent v-show="showTK">
      <div class="tankuang">
        <img
          :src="this.tkInfo.Thematic"
          alt=""
          @click="gotoUrl1(tkInfo.RIId)"
        />
        <img
          class="close"
          @click="closeTK"
          src="http://alycommunity.anluoyun.cn/lc-lcxms/2023/9/84367be0-fd8.png"
        />
      </div>
    </div> -->
    <!-- <div
      v-if="showPage == '1'"
      style="
        position: fixed;
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        width: 100%;
        height: 100%;
      "
    >
      <iframe
        frameborder="0"
        width="100%"
        height="100%"
        :src="subPage"
      ></iframe>
    </div> -->
    <div class="top">
      <img
        src="https://dximg.dexian.ren/lczhsq/Banner.png"
        alt=""
        style="width: 100%"
      />
      <div class="text">
        <!-- <span style="letter-spacing: 3px"></span> -->
        <div style="letter-spacing: 3px">游奇境栾川</div>
        <div style="letter-spacing: 3px">住樊营民宿</div>
        <!-- <div style="letter-spacing: 1.3px">
          <span class="text1"></span>
          <span class="text1">住樊营民宿</span>
        </div> -->
      </div>
    </div>
    <div class="mid">
      <div style="position: relative; z-index: 9">{{ OrgName }}欢迎您</div>
      <div class="i"></div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 6px;
          margin-bottom: 14px;
        "
      >
        <div class="name">{{ OrgName }}</div>
        <div class="address" @click="gotominiProgram()">
          <img
            style="width: 16px"
            src="https://dximg.dexian.ren/lczhsq/focus.png"
          />当前位置
        </div>
      </div>
      <div @click="show = true" class="day">
        <div style="width: 100px">
          <div>入住 {{ weekStart }}</div>
          <div class="date">{{ start }}</div>
          <!-- <div v-else class="date" style="opacity: 0.8">入住日期</div> -->
        </div>
        <div style="width: 15%">一</div>
        <div style="width: 106px">
          <div>离店 {{ weekEnd }}</div>
          <div class="date">{{ end }}</div>
          <!-- <div v-else class="date" style="opacity: 0.8">离店日期</div> -->
        </div>
        <div class="wan">共{{ DiffDay }}晚</div>
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      ></div>
      <!-- 日期 -->
      <van-calendar
        v-model="show"
        type="range"
        :formatter="formatter"
        @confirm="onConfirm"
      />
      <input
        v-model="search"
        class="jiudian"
        placeholder="民宿名称/关键词"
        placeholder-class="placeholder"
      />
      <!-- @click="searchOn" -->
      <van-button class="btn" @click="gotoUrl('/hotels/moreRooms/' + Start)"
        >搜索民宿</van-button
      >
    </div>
    <div style="text-align: center; margin-top: -12px">
      <img
        style="width: 94%"
        src="https://dximg.dexian.ren/lczhsq/banner01.png"
        alt=""
      />
    </div>
    <div class="list">
      <!-- <van-tabs
        @click="onClickOnlyOne"
        color="#FEDE00"
        title-active-color="#1E1E1E"
      >
        <van-tab
          v-for="item in typeList"
          :key="item.Coding"
          :name="item.Coding"
          :title="item.Name"
        >
        </van-tab>
      </van-tabs> -->
      <van-list
        class="vanList"
        v-model="loading"
        :finished="finished"
        offset="20"
        :immediate-check="false"
        @load="onLoad"
      >
        <!--   finished-text="没有更多了 ~"
        -->

        <div
          class="Box"
          style="display: flex; justify-content: space-evenly; flex-wrap: wrap"
        >
          <!-- @click="getScore(item.RoomId)" -->
          <div
            @click="gotoUrl('/hotels/hotelsXq/' + item.RoomId + '/' + Start)"
            class="li"
            v-for="item in list"
            :key="item.RoomId"
          >
            <img
              alt=""
              v-if="item.Imgs != null && item.Imgs != ''"
              :src="item.Imgs.split(',')[0]"
            />
            <img
              v-else
              src="http://alycommunity.anluoyun.cn/lc-lcx/2023/6/35e14c95-10b.png"
            />
            <div style="box-sizing: border-box; padding: 2px 7.5px 12px">
              <div class="tit van-multi-ellipsis--l2">{{ item.RoomTitle }}</div>
              <div style="display: flex; align-items: center; margin-top: 6px">
                <img
                  src="../../assets/xing.png"
                  alt=""
                  style="width: 12px; height: 12px; margin-right: 2px"
                />
                <div class="score">{{ item.AllScore }}</div>
                <!-- <div class="good">超棒</div> -->
                <!-- <div class="type">经济型</div> -->
              </div>
              <div v-if="item.DisPrice != 0" class="price">
                <span class="price1">¥</span>{{ item.DisPrice
                }}<span class="price1">起</span>
              </div>
              <div v-else class="price">
                <span class="price2">面议</span>
              </div>
              <!-- <div class="tips">免费停车 ❘ 24小时前台</div> -->
              <div class="tips van-ellipsis" v-if="item.RServiceNames">
                {{ item.RServiceNames.replace(/,/g, " | ") }}
              </div>
            </div>
          </div>
          <!-- <div class="li"></div> -->
        </div>
        <!-- 更多房源 -->
        <div style="text-align: center">
          <van-button
            @click="gotoUrl('/hotels/moreRooms/' + Start)"
            class="btn btn1"
            >更多房源</van-button
          >
        </div>
      </van-list>
      <!-- <van-button v-if="showBtn" @click="backTop" class="backTop"
        ><img
          src="https://dximg.dexian.ren/lczhsq/top.png"
          style="width: 13px; vertical-align: middle"
          alt=""
      /></van-button> -->
    </div>
    <!-- 搜索 -->
    <!-- <van-sticky :offset-top="0">
      <van-search
        v-model="search"
        placeholder="请输入民宿名称"
        @search="onSearch"
        shape="round"
        left-icon="http://lyxnyapi.dexian.ren//upload/images/2022/7/714ba889-821.png"
      ></van-search>
      <div class="searchOn" @click="searchOn">搜索</div>
    </van-sticky> -->
    <!-- 轮播图 -->
    <!-- <div class="swipeBox">
      <van-swipe class="my-swipe"
        :autoplay="3000"
        indicator-color="white">
        <van-swipe-item><img src="../../assets/商家轮播.png"
            style="width: 100%;height: 100%;"></van-swipe-item>
        <van-swipe-item><img src="../../assets/qiye.png"
            style="width: 100%;height: 100%;"></van-swipe-item>
      </van-swipe>
    </div> -->
    <!-- swiper切换栏 -->
    <!-- <div style="border-radius: 5px;">
      <div style="border-radius: 5px;">
        <van-swipe class="my-swipe"
          indicator-color="white"
          loop>
          <van-swipe-item>
            <van-grid class="wktype"
              :border="false"
              :square="true"
              column-num="5">
              <template>
                <van-grid-item icon="photo-o"
                  @click="gotoUrl('/shop/PromotionList?id=01')">
                  <van-image class="iconimg"
                    src="https://lyxnyapi.dexian.ren//upload/images/2022/7/91b592c7-0c4.png"></van-image>
                  <span class="wktext">商超百货</span>
                </van-grid-item>
              </template>
              <van-grid-item icon="photo-o"
                @click="gotoUrl('/shop/PromotionList?id=02')">
                <van-image class="iconimg"
                  src="https://lyxnyapi.dexian.ren//upload/images/2022/7/26e26f0e-33e.png"></van-image>
                <span class="wktext">滋补保健</span>
              </van-grid-item>
              <van-grid-item icon="photo-o"
                @click="gotoUrl('/shop/PromotionList?id=03')">
                <van-image class="iconimg"
                  src="https://lyxnyapi.dexian.ren//upload/images/2022/7/fd46f477-eff.png"></van-image>
                <span class="wktext">粮油调味</span>
              </van-grid-item>
              <van-grid-item icon="photo-o"
                @click="gotoUrl('/shop/PromotionList?id=04')">
                <van-image class="iconimg"
                  src="https://lyxnyapi.dexian.ren//upload/images/2022/7/f2e4af73-093.png"></van-image>
                <span class="wktext">医疗健康</span>
              </van-grid-item> -->
    <!-- <template>
                <van-grid-item icon="photo-o"
                  @click="gotoUrl('/shop/PromotionList?id=05')">
                  <van-image class="iconimg"
                    src="https://lyxnyapi.dexian.ren//upload/images/2022/7/a61201d8-dc7.png"></van-image>
                  <span class="wktext">母婴幼儿</span>
                </van-grid-item>
              </template>
              <van-grid-item icon="photo-o"
                @click="gotoUrl('/shop/PromotionList?id=06')">
                <van-image class="iconimg"
                  src="https://lyxnyapi.dexian.ren//upload/images/2022/7/7c15d48f-7aa.png"></van-image>
                <span class="wktext">电器数码</span>
              </van-grid-item>
              <van-grid-item icon="photo-o"
                @click="gotoUrl('/shop/PromotionList?id=07')">
                <van-image class="iconimg"
                  src="https://lyxnyapi.dexian.ren//upload/images/2022/7/71f7de50-bc2.png"></van-image>
                <span class="wktext">美妆/服饰</span>
              </van-grid-item>
              <van-grid-item icon="photo-o"
                @click="gotoUrl('/shop/PromotionList?id=08')">
                <van-image class="iconimg"
                  src="https://lyxnyapi.dexian.ren//upload/images/2022/7/a31a7675-343.png"></van-image>
                <span class="wktext">日用清洁</span>
              </van-grid-item>
              <template>
                <van-grid-item icon="photo-o"
                  @click="gotoUrl('/shop/PromotionList?id=09')">
                  <van-image class="iconimg"
                    src="https://lyxnyapi.dexian.ren//upload/images/2022/7/bc192941-0f8.png"></van-image>
                  <span class="wktext">宠物生活</span>
                </van-grid-item>
              </template> -->
    <!-- <van-grid-item icon="photo-o"
                @click="gotoUrl('/shop/PromotionType')">
                <van-image class="iconimg"
                  src="https://lyxnyapi.dexian.ren//upload/images/2022/7/39457095-1ec.png"></van-image>
                <span class="wktext">更多</span>
              </van-grid-item>
            </van-grid>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div> -->
    <!-- 商品图 -->
    <!-- <div style="margin: 10px auto;width: 95%;">
      <img src="../../assets/shop/bg1.png"
        style="width: 100%;">
    </div> -->
    <!-- 商品列表 -->
    <!-- <div class="title">促销活动</div> -->
    <!-- <div class="information" style="margin-bottom: 55px">
      <van-list
        v-model="loading"
        :finished="finished"
        offset="50"
        @load="onLoad"
        :immediate-check="false"
      >
        <div
          class="center"
          style="padding: 0"
          v-for="item in list"
          :key="item.index"
        >
          <div @click="gotoUrl('/hotels/hotelsXq/' + item.RoomId)">
            <van-card
              style="
                padding: 0px 0px 10px 0;
                background-color: white;
                border-radius: 8px;
              "
            >
              <template #thumb>
                <div class="van-card__thumb">
                  <img
                    v-if="item.Imgs != null && item.Imgs != ''"
                    style="border-radius: 5px; width: 100%"
                    :src="item.Imgs.split(',')[0]"
                  />
                  <img
                    v-else
                    style="border-radius: 5px; width: 100%"
                    src="http://alycommunity.anluoyun.cn/lc-lcx/2023/6/35e14c95-10b.png"
                  />
                </div>
              </template>
              <template #title>
                <div
                  class="centerTitle van-ellipsis--l1"
                  style="width: 75%; margin: 6px 0"
                >
                  {{ item.Name }}
                </div>
              </template>

              <template #tags>
                <div
                  style="width: 75%; font-size: 13px; margin: 6px 0"
                  class="van-multi-ellipsis--l2"
                >
                  {{ item.TouchTel }}
                </div>
                <van-tag
                  class="tagClass"
                  round
                  type="primary"
                  color="#013f92"
                  v-if="item.GradeDesc != ''"
                  text-color="white"
                  >{{ item.GradeDesc }}
                </van-tag>
                <div
                  style="width: 75%; font-size: 13px; margin: 6px 0"
                  class="van-multi-ellipsis--l2"
                >
                  {{ item.Address }}
                </div>
              </template>
              <template #num>
                <div style="color: #d8222b; font-weight: 550">
                  <span>￥</span>
                  <span style="font-size: 18px">98</span>
                  <span style="color: #808080"> 起</span>
                </div>
              </template>
            </van-card>
          </div>
        </div>
      </van-list>
      <div v-if="list.length == 0">
        <van-empty description="暂无民宿" />
      </div>
    </div> -->

    <!-- <van-list v-model="loading" :finished="finished" offset="50" @load="onLoad">
      <div class="lists">
        <div
          class="list"
          @click="gotoUrl('/hotels/hotelsXq/' + item.RoomId)"
          v-for="item in list"
          :key="item.RoomId"
        >
          <div class="imgs">
            <img
              v-if="item.Imgs != null && item.Imgs != ''"
              :src="item.Imgs[0]"
              style="border-radius: 5px"
            />
            <img
              v-else
              style="border-radius: 5px"
              src="http://alycommunity.anluoyun.cn/lc-lcx/2023/6/35e14c95-10b.png"
            />
          </div>
          <div class="listTitle">{{ item.Name }}</div>
          <div class="price">
            <span style="font-weight: 700; font-size: 14px">{{
              item.Name
            }}</span>
            <span class="shopType" v-if="item.GradeDesc">{{
              item.GradeDesc
            }}</span>
          </div>
          <div class="shopBox van-ellipsis--l2" style="margin: auto">
            <span>{{ item.Introduce }}</span>
          </div>
        </div>
      </div>
    </van-list> -->
    <!-- tabbar栏 -->
    <!-- <van-tabbar v-model="tabbaron"
      active-color="#3a95cc"
      inactive-color="#ccc">
      <van-tabbar-item replace
        to="/shop"
        icon="shop">商城首页</van-tabbar-item>
      <van-tabbar-item replace
        to="/PromotionBm"
        icon="user-o">报名信息</van-tabbar-item>
    </van-tabbar> -->
    <div id="allmap" style="display: none"></div>
  </div>
</template>
<script type="text/javascript" src="https://api.map.baidu.com/api?v=2.0&ak=Liz5cx8NFb67bi5f97L81wjBufDzl6ew"></script>
<script>
import { ref } from "vue";
import { Wx_GetHotelRoomList, WeGetRealInfoPage } from "@/api/Hotel";
import { GetCodeEntrysList } from "@/api/ResumeJS";
import { getOCode, setOpenId, setScrollTop, getScrollTop } from "@/utils/auth";
import { loadBMap } from "../fangyi/baiduLocation.js";
export default {
  setup() {
    const tabbaron = ref(0);
    return { tabbaron };
  },
  data() {
    return {
      showPage: "0", // showPage初始化，0隐藏，1显示
      Coordinate: "",
      show: false, // 时间
      riKind: 0, //tab栏
      list: [], //资讯列表
      listfrom: {
        page: 1,
        limit: 10,
        // useTo: "01",
        useTo: "",
        orgCode: undefined,
      }, //查询传参
      search: "", //搜索内容
      hidden: true, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
      typeList: [],
      tabbaron: 0,
      date: "",
      start: this.formatDate(new Date()),
      end: this.formatDate(
        new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      ),
      DiffDay: 1,
      weekStart: "",
      weekEnd: "",
      showBtn: false,
      OrgName: "樊营村",
      Start: this.formatDateTime(new Date()),
      showTK: true,
      tkInfo: {},
    };
  },
  created() {
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    // setTimeout(() => {
    //   console.log("!!!!!!!!!!!");
    //   console.log(getScrollTop());
    //   document.body.scrollTop = getScrollTop();
    // }, 500);
    // console.log(getScrollTop());
    var start = new Date();
    var dayStart = this.Week(start.getDay()); //获取星期几
    this.weekStart = dayStart;
    var end = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    var dayEnd = this.Week(end.getDay()); //获取星期几
    this.weekEnd = dayEnd;
  },
  methods: {
    // 获取弹框广告
    async getRealInfoPage() {
      const res = await WeGetRealInfoPage({ tkId: 56 });
      if (res.data.data) {
        this.tkInfo = res.data.data[0];
        console.log(this.tkInfo);
      }
    },
    closeTK() {
      this.showTK = false;
    },
    //停止页面滚动
    stopMove() {
      let m = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", m, { passive: false }); //禁止页面滑动
    },
    //开启页面滚动
    Move() {
      let m = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "";
      //出现滚动条
      document.removeEventListener("touchmove", m, { passive: true });
    },
    // 保留一位小数
    returnFloat1(value) {
      value = Math.round(parseFloat(value) * 10) / 10;
      if (value.toString().indexOf(".") < 0) {
        value = value.toString() + ".0";
      }
      return value;
    },
    // 使用pushState方法往history里增加url链接：原url加个#,还是原页面并且停留在之前浏览的位置
    pushHistory() {
      var state = {
        title: "title",
        url: "#",
      };
      window.history.pushState(state, "title", "#");
    },

    // 打开内嵌网页
    getScore(id) {
      const that = this;
      // 内嵌网页的url
      that.subPage = "http://192.168.1.11:8081/#/hotels/hotelsXq/" + id;
      // that.subPage = "https://lcxapp.anluoyun.cn/#/hotels/hotelsXq/" + id;
      // 内嵌网页显示
      that.showPage = "1";
      // 放入停留在之前浏览的位置的url，这个一定不要漏，不然返回的时候就直接退出列表页了！
      that.pushHistory();
      // 监听返回键
      window.addEventListener("popstate", function (e) {
        // 内嵌网页隐藏
        that.showPage = "0";
      });
    },

    // 打开另一个内嵌网页
    // getRatings(name, date) {
    //   const that = this;
    //   that.subPage = "ratings.html?name=" + name + "&date=" + date;
    //   that.showPage = "1";
    //   that.pushHistory();
    //   window.addEventListener("popstate", function (e) {
    //     that.showPage = "0";
    //   });
    // },

    handleScroll() {
      const scrollTop = this.getScroll().top;
      this.scrollTop = scrollTop;
    },
    //获得页面向左、向上卷动的距离
    getScroll() {
      return {
        // left:
        //   window.pageXOffset ||
        //   document.body.scrollLeft ||
        //   document.body.scrollLeft ||
        //   0,
        top:
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0,
      };
    },
    Week(dayStart) {
      if (dayStart === 0) {
        return "周日";
      } else if (dayStart === 1) {
        return "周一";
      } else if (dayStart === 2) {
        return "周二";
      } else if (dayStart === 3) {
        return "周三";
      } else if (dayStart === 4) {
        return "周四";
      } else if (dayStart === 5) {
        return "周五";
      } else if (dayStart === 6) {
        return "周六";
      }
    },
    backTop() {
      // 开个定时器返回顶部时缓慢优雅一些，是 document.body.scrollTop = 0 的升级版
      const timer = setInterval(() => {
        // 页面顶部卷去高度
        let scrollTop =
          document.body.scrollTop || document.documentElement.scrollTop;
        // 高度控速 = 页面顶部卷去高度正值 / n ===> n 越大，返回越慢
        let ispeed = Math.floor(-scrollTop / 5);
        // 页面顶部卷去高度 + 高度控速 ===> 和不为零，定时器一直开启（不明白的同志可以尝试同时打印 scrollTop 和 ispeed）
        document.body.scrollTop = scrollTop + ispeed;
        // 页面顶部卷去高度 + 高度控速 ===> 和为零返回顶部，清除定时器
        if (scrollTop === 0) {
          clearInterval(timer);
        }
      }, 20);
    },
    showBtnF() {
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop > 100) {
        this.showBtn = true;
      } else {
        this.showBtn = false;
      }
    },
    gotominiProgram() {
      // 百度地图API功能
      var that = this;
      window.initBaiduMapScript = () => {
        that.BMap = window.BMap;
        var map = new window.BMap.Map("allmap");
        var point = new that.BMap.Point(116.331398, 39.897445);
        map.centerAndZoom(point, 18);

        var geolocation = new that.BMap.Geolocation();
        geolocation.getCurrentPosition(
          function (r) {
            if (this.getStatus() == BMAP_STATUS_SUCCESS) {
              console.log(r);
              var mk = new BMap.Marker(r.point);
              map.addOverlay(mk);
              map.panTo(r.point);
              that.Coordinate = [
                { lng: r.point.lng, lat: r.point.lat, of: "inner" },
              ];
              // alert("您的位置：" + r.point.lng + "," + r.point.lat);
            } else {
              // alert("failed" + this.getStatus());
            }
            console.log(JSON.stringify(that.Coordinate), "that.Coordinate");
            this.nameCc = {
              Navigation: "导航",
              Coordinate: JSON.stringify(that.Coordinate),
            };
            if (window.__wxjs_environment === "miniprogram") {
              window.wx.miniProgram.navigateTo({
                url:
                  "../openminip/openminip?name=" + JSON.stringify(this.nameCc),
              });
            } else {
              alert("请在小程序中打开");
            }
          },
          { enableHighAccuracy: true }
        );
      };
      loadBMap("initBaiduMapScript");
      // if (navigator.geolocation) {
      //   var geolocation = new window.BMap.Geolocation();
      //   geolocation.getCurrentPosition(
      //     function (r) {
      //       if (this.getStatus() == BMAP_STATUS_SUCCESS) {
      //         alert("您的位置：" + r.point.lng + "," + r.point.lat);
      //       } else {
      //         alert("failed" + this.getStatus());
      //       }
      //     },
      //     { enableHighAccuracy: true }
      //   );
      // } else {
      //   alert("Geolocation is not supported by this browser.");
      // }
      // return;
      // if (navigator.geolocation) {
      //   navigator.geolocation.getCurrentPosition((res) => {
      //     console.log(res); //这里会返回经纬度，然后还要通过经纬度转换地区名称
      //   });
      // }
      // console.log(details);
      // if (details.Coordinate == "" || details.Coordinate == null) {
      //   Toast.fail("暂无位置信息");
      // }
      // else {
    },
    formatter(day) {
      const month = day.date.getMonth() + 1;
      const date = day.date.getDate();
      // console.log(month, "month");
      // console.log(date, "date");
      if (month === 5) {
        if (date === 1) {
          day.topInfo = "劳动节";
        } else if (date === 4) {
          day.topInfo = "青年节";
        } else if (date === 11) {
          day.text = "今天";
        }
      }

      if (day.type === "start") {
        day.bottomInfo = "入住";
      } else if (day.type === "end") {
        day.bottomInfo = "离店";
      }

      return day;
    },
    onConfirm(date) {
      console.log(date, "date");
      const [start, end] = date;
      var dayStart = this.Week(start.getDay()); //获取星期几
      this.weekStart = dayStart;
      var dayEnd = this.Week(end.getDay()); //获取星期几
      this.weekEnd = dayEnd;
      this.show = false;
      this.start = this.formatDate(start); //08-03
      this.Start = this.formatDateTime(start); // 2023-08-10
      this.end = this.formatDate(end);
      this.DiffDay = this.getDiffDay(start, end);
    },
    //计算日期间隔天数
    getDiffDay(date_1, date_2) {
      // 计算两个日期之间的差值
      let totalDays, diffDate;
      let myDate_1 = Date.parse(date_1);
      let myDate_2 = Date.parse(date_2);
      // 将两个日期都转换为毫秒格式，然后做差
      diffDate = Math.abs(myDate_1 - myDate_2); // 取相差毫秒数的绝对值
      totalDays = Math.floor(diffDate / (1000 * 3600 * 24)); // 向下取整
      // console.log(totalDays)
      return totalDays; // 相差的天数
    },
    formatDateTime(val) {
      var timestamp = Date.parse(val);
      var date = new Date(timestamp);
      //获取年份
      var Y = date.getFullYear();
      //获取月份
      var M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      //获取当日日期
      // console.log();
      var day = date.getDate();
      var D = day < 10 ? "0" + day : day;

      this.minDate = new Date(Y, M - 1, D);
      return Y + "-" + M + "-" + D;
    },
    formatDate(date) {
      return `${date.getMonth() + 1}月${date.getDate()}日`;
    },
    onClickOnlyOne(name) {
      console.log(name);
      this.listfrom.useTo = name;
      this.getList();
    },
    // 搜索
    searchOn() {
      this.list = [];
      this.listfrom.page = 1;
      this.getList(this.search);
    },
    // 跳转
    gotoUrl(path) {
      if (window.__wxjs_environment === "miniprogram") {
        // 页面 不会重新加载
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        // 页面会 重新加载(如:列表页 查看详情后会返回顶部)
        this.$router.push(path);
      }
    },
    // 跳转
    gotoUrl1(RIId) {
      console.log(RIId);
      if (window.__wxjs_environment === "miniprogram") {
        // 页面 不会重新加载
        window.wx.miniProgram.navigateTo({
          url: "../detail/detail?RIId=" + RIId,
        });
      } else {
        alert("请在小程序中打开");
      }
    },
    // 点击进入商户详情
    detailClick(id) {
      var url = "/shop/detail?Id=" + id;
      this.$router.push(url);
    },
    // 获取字典

    // 获取分页列表
    getList(searchKey) {
      console.log(this.Start, "this.Start");
      this.listfrom.inTime = this.Start;
      this.listfrom.searchKey = searchKey;
      Wx_GetHotelRoomList(this.listfrom).then((res) => {
        if (res.data.code == 0) {
          this.list = res.data.data;
          this.finished = true;
          /**第二种 */
          //   let rows = res.data.data; //请求返回当页的列表
          //   // 加载状态结束

          //   if (rows == null || rows.length === 0) {
          //     // 加载结束
          //     console.log("结束");

          //     return;
          //   }
          //   // 将新数据与老数据进行合并
          //   this.list = this.list.concat(rows);
          this.list.forEach((i) => {
            i.AllScore = this.returnFloat1(i.AllScore);
          });
          //   this.loading = false;
          //   // 如果列表数据条数>=总条数，不再触发滚动加载
          //   if (this.list.length >= res.data.count) {
          //     this.finished = true;
          //   }
          // } else {
          //   this.finished = true;
          // }

          // if (res.data.code == 0) {
          //   if (res.data.count == 0) {
          //     this.finished = true;
          //     this.list = {};
          //   } else {
          //     this.list = res.data.data;
          //     this.total = res.data.count;
          //     this.finished = true;
          //     for (var i = 0; i < this.list.length; i++) {
          //       if (this.list[i].Imgs != null && this.list[i].Imgs != "") {
          //         var ImgsList = this.list[i].Imgs.split(',')
          //         this.list[i].Imgs = ImgsList
          //       }
          //     }
          //   }
          console.log(this.list);
        }
      });
    },
    // 下拉刷新
    onLoad() {
      this.loading = true;
      this.listfrom.page = this.listfrom.page + 1;
      this.getList(undefined);
    },
    // tan点击切换事件
    onClick(e) {
      this.riKind = e;
      this.listfrom.page = 1;
      // this.getList(undefined);
    },
    // 搜索
    onSearch() {
      this.listfrom.page = 1;
      this.getList(undefined);
    },
    // 时间格式化
    dataFormat(value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
    // 字典
    getTypeList() {
      GetCodeEntrysList({ kind: "603" }).then((res) => {
        this.typeList = res.data.data;
      });
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);

    console.log(this.$route.params.name);
    // this.OrgName = this.$route.params.name;
    window.addEventListener("scroll", this.showBtnF, true);
    if (getOCode() != undefined) {
      if (JSON.parse(getOCode())) {
        this.listfrom.orgCode = JSON.parse(getOCode()).orgCode;
      } else {
        this.listfrom.orgCode = undefined;
      }
    }
    this.getList(undefined);
    this.getTypeList();
    this.getRealInfoPage();
  },
};
</script>
<style scoped>
@font-face {
  font-family: "Price";
  src: url(../../assets//font//OPPOSANS-B.TTF);
}
.shop {
  background: #f6f7fb;
  overflow: hidden;
}

.top {
  position: relative;
}

.text {
  position: absolute;
  top: 59%;
  transform: translateY(-50%);
  left: 20px;
}

.text {
  font-size: 24px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  color: #fefefe;
}
.text div:nth-child(1) {
  font-size: 24px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  color: #fefefe;
  opacity: 0.56;
}
.text1 {
  font-size: 14px !important;
  font-family: PingFang SC !important;
  color: #fefefe !important;
  margin-top: 10px;
  display: inline-block;
}
.day {
  position: relative;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #1e1e1e;
  display: flex;
  /* justify-content: space-between; */
  padding-top: 14px;
  align-items: center;
  padding-bottom: 12px;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}

.mid {
  top: -26px;
  width: 94%;
  position: relative;
  margin: auto;
  height: 286px;
  background: #ffffff;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 18px 21px 15px;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1e1e1e;
}
.mid .name {
  font-size: 21px;
  font-weight: 800;
}
.mid .address {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #0f95f7;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mid .i {
  width: 90px;
  height: 5px;
  background: linear-gradient(to left, rgb(254, 222, 0), rgb(255, 255, 255));
  border-radius: 3px;
  position: relative;
  top: -8px;
}

.date {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #1e1e1e;
}

.wan {
  margin: auto;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #1e1e1e;
}

.jiudian {
  padding: 13px;
  font-size: 17px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #1e1e1e;
  border: none;
  width: 94%;
}
input::placeholder {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400 !important;
  color: #a2a2a2;
}
.btn {
  width: 100%;
  height: 47px;
  background: #fede00;
  border-radius: 23.5px;
  border: 1px solid #fede00;
  font-size: 19px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #1e1e1e;
  margin-top: 6px;
}

.btn1 {
  width: 80%;
  height: 40px;
  font-size: 16px;
  margin-top: 22px;
}

/deep/ .van-calendar__confirm {
  height: 42px;
}
/deep/ .van-calendar__day--start,
/deep/ .van-calendar__day--end {
  color: #1e1e1e;
  background-color: #f8de4b;
}

/deep/ .van-calendar__day--middle::after {
  background-color: #f8de4b;
  opacity: 0.19;
}
/deep/ .van-calendar__day--middle {
  color: #1e1e1e;
}
/deep/ .van-button--danger {
  background: #fede00;
  border: 1px solid #fede00;
  color: #1e1e1e;
}

.list {
  padding-bottom: 26px;
  margin-bottom: 20px;
  /* height: 500px; */
  /* background: #fdfdfe; */
  /* border: 1px solid #fede00; */
  /* border: 1px solid;
  border-image: linear-gradient(to bottom, #fede00, #fff) 1; */
  border: 1px solid transparent;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to bottom, #fdfdfe, #f5f6fa),
    linear-gradient(to bottom, #fede00 20px, #f6f7fb 400px);
  /* background-image: url(https://dximg.dexian.ren/lczhsq/list.png); */
  background-repeat: no-repeat;
  border-radius: 15px;
  width: 95%;
  margin: auto;
  margin-top: 12px;
  overflow: hidden;
}
/deep/ .van-tab__text {
  z-index: 9;
}
/deep/ .van-tabs__line {
  height: 5px;
  background: linear-gradient(to left, rgb(254, 222, 0), rgb(255, 255, 255));
  bottom: 28px;
  width: 65px;
}

/deep/ .van-tab {
  font-size: 15px;
  color: #1e1e1e;
}

/deep/ .van-tab--active {
  font-size: 17px;
  font-weight: bold;
}

.li {
  width: 45.5%;
  background: #fff;
  border-radius: 8px;
  margin-top: 10px;
}

.li img {
  width: 100%;
  height: 145px;
  border-radius: 8px;
}
.tit {
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #1e1e1e;
}

.score {
  /* width: 25px;
  height: 15px; */
  /* background: #0dd3e8;
  border-radius: 8px 8px 0px 8px; */
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: bold;
  /* color: #fefefe; */
  color: #1e1e1e;
  text-align: center;
  line-height: 15px;
  margin-right: 2px;
}

.good {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #0dd3e8;
  margin-right: 6px;
}

.type {
  width: 50px;
  height: 16px;
  background: #e8e6fe;
  border-radius: 3px 3px 12px 3px;
  font-size: 9px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #29283d;
  text-align: center;
  line-height: 16px;
}

.price {
  font-family: "Price";
  color: #fe411b;
  font-size: 19px;
  margin-top: 8px;
}

.price1 {
  font-family: PingFang SC;
  font-size: 12px;
  vertical-align: bottom;
}
.price2 {
  font-family: PingFang SC;
  font-size: 14px;
  vertical-align: bottom;
}
.tips {
  margin-top: 9px;
  font-size: 11px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #6f6f6f;
}
.backTop {
  position: fixed;
  bottom: 40px;
  right: 17px;
  width: 35px;
  height: 35px;
  background: rgba(255, 255, 255, 0.68);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.06);
  border-radius: 50%;
}

/* 旧的 样式 */
.van-card__thumb {
  margin-right: 22px;
  margin-left: 5px;
}

.shopTitle {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  padding: 10px;
}

.shop .information .center {
  /* border-bottom: none; */
  border-bottom: 1px solid #f4f4f4;
  padding: 0px;
  border-radius: 5px;
}

.shop .center {
  width: 95%;
  border-radius: 8px;
  margin: 10px auto;
  /* box-shadow: 0px 5px 17px 1px rgb(0 0 0 / 7%); */
  /* background: rgb(246, 243, 243); */
}

.centertime {
  font-size: 14px !important;
}

.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}

/deep/.van-field__left-icon {
  margin-right: 6px;
  margin-top: 2px;
}

/deep/.van-search__content--round {
  border: 1px solid #fdd64f;
  background: white;
}

/deep/.van-search {
  /* background: #f4f4f4; */
}

.wktype {
  padding: 0 5px 5px;
}

.wktype .iconimg {
  width: 65%;
}

.wktype .wktext {
  margin-top: 8px;
  font-size: 0.75rem;
}

.lists {
  column-count: 2;
  column-gap: 0px;
  width: 100%;
  margin: 10px auto;
  padding-bottom: 90px;
}

/deep/ .van-tabs__nav {
  background: #fdfdfe;
}
.van-ellipsis--l2 {
  display: -webkit-box !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
}
.list .listTitle {
  font-size: 14px;
  font-weight: 700;
  margin: 0px 5px;
  color: #69afe3;
}

.list .imgs {
  height: 100%;
  overflow: auto;
  margin: 0 0px;
  padding: 10px;
}

.list .imgs img {
  max-height: 300px;
  width: 100%;
  min-height: 135px;
  height: 100%;
  overflow: auto;
}

.list .price {
  /* color: #222426;
  margin: 5px 5px;
  padding-bottom: 5px; */
}
.list .shopType {
  background: #d9841a;
  color: #fff;
  padding: 3px 6px;
  border-radius: 8px;
  font-size: 12px;
  margin-left: 6px;
}
.title {
  position: relative;
  font-weight: 700;
  margin: 10px 20px;
  font-size: 16px;
}

.title::before {
  content: "";
  position: absolute;
  top: 0px;
  left: -10px;
  width: 5px;
  height: 21px;
  background: #fdd64f;
  border-radius: 5px;
}

.searchOn {
  position: absolute;
  top: 13px;
  right: 15px;
  background: linear-gradient(to bottom right, #ffe03e, #ffbe00);
  border-radius: 15px;
  padding: 6px 18px;
  color: #3a3a3a;
  font-size: 14px;
  font-weight: 700;
}

.shopBox {
  display: flex;
  align-items: center;
  width: 90%;
  margin: 3px 0%;
  /* padding: 0px 0px 5px; */
  color: #808080;
  font-size: 12px;
}

.swipeBox {
  width: 95%;
  margin: 5px 2.5%;
  height: 150px;
}

.swipeBox .van-swipe {
  height: 150px;
  border-radius: 8px;
}

/deep/.wktype .van-grid-item__content {
  /* background: #f7f7f7; */
}
.tagClass {
  margin: 5px 0;
  padding: 1px 5px;
  /* border-top-right-radius: 0; */
}

.Box::after {
  content: "";
  width: 45.5%;
  height: 0;
  visibility: hidden;
}

.tankuang {
  position: absolute;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 5%;
}

.tankuang img:first-child {
  width: 86%;
  border-radius: 8px;
}

.image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
}

.close {
  width: 30px;
  height: 30px;
  margin-top: 15px;
}
</style>